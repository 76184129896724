import { SubmittedDocument } from "types/SubmittedDocument";

export const useIsDocumentReadyToGrade = (
  submittedDocument: SubmittedDocument
) => {
  return [
    DocumentState.studentReview,
    DocumentState.submitted,
    DocumentState.evaluating,
    DocumentState.grading,
    DocumentState.graded,
  ].includes(submittedDocument.state);
};
