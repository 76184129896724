import { DocumentViewMode } from "~/types/enums/DocumentViewMode.enum";

export const useCanGradeDocument = (
  submittedDocument: SubmittedDocument,
  mode: DocumentViewMode
) => {
  if (mode == DocumentViewMode.student) return true;

  if (submittedDocument == undefined || submittedDocument.isSample == true)
    return true;

  const capabilitiesService = useCapabilitiesServiceStore();
  const { canGradeDocument: checkCanGrade } = storeToRefs(capabilitiesService);

  return checkCanGrade.value(submittedDocument.id!) == true;
};
