import { DocumentViewMode } from "~/types/enums/DocumentViewMode.enum";

export const useGenerateDocumentPath = (
  submittedDocument: SubmittedDocument,
  mode: DocumentViewMode
): string | undefined => {
  if (useCanGradeDocument(submittedDocument, mode) == false) return undefined;

  if (
    mode == DocumentViewMode.student &&
    [DocumentState.graded, DocumentState.studentReview].includes(
      submittedDocument.state
    ) != true
  ) {
    return undefined;
  }

  return useIsDocumentReadyToGrade(submittedDocument)
    ? `/documents/${mode}/review/${submittedDocument.id}`
    : undefined;
};
